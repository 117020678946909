import * as React from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { Layout, Shared } from '../../components';

const ThankSharingIssueQuestionPage = () => {
  return (
    <>
      <Layout.NavMenu solidColor notFixed />
      <section className="bg-primary text-white mt-n4 py-5">
        <Container fluid="lg" className="content">
          <Row>
            <Col className="text-start">
              <h1>Thank you for submitting your issue or question.</h1>
              <p>An Icon Property Tax Appeal team member will respond to you shortly.</p>
            </Col>
          </Row>
        </Container>
      </section>
      <Shared.TaxAppealsMadeEasy />
      <Layout.Footer />
    </>
  );
};

export default ThankSharingIssueQuestionPage;

export const Head = () => <Layout.PageHead subtitle="Thank you for sharing your issue or question" googleNoIndex />;
